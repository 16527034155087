<template>
	<div class="playlistCreate">

		<h1 class="text-center">Wann trainierst du immer?</h1>

		<div class="fitnessplanTimeSelection">
            <div class="stepper">
                <div class="stepper__icon stepper__icon__down">
                    <img v-on:click="adjustTime(-30)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
                </div>
                <div class="stepper__value">
                    <input v-model="startTime" type="time" class="stepper__value" />
                </div>
                <div class="stepper__icon stepper__icon__up">
                    <img v-on:click="adjustTime(30)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
                </div>
            </div>
		</div>

        <small>Die Uhrzeit kann später für jede Einheit individuell geändert werden.</small>

        <v-btn :disabled="!startTime "
               class="button button--primary"
               @click="proceedAcceptChallenge">Weiter</v-btn>
	</div>
</template>
<script>

export default {
    name: 'FitnessplanChallengeSelectTime',
    data() {
        return {
            // use a default value here to avoid that the input field is invisble on iOS/Android
            startTime: "18:00",
            startDate: "",
        }
    },
    mounted() {
        this.startDate = this.$route.query.startDate;
    },
    methods: {
        adjustTime(d) {
            let timeComponents = this.startTime.split(":");
            var minutes = parseInt(timeComponents[0]) * 60 + parseInt(timeComponents[1]);
            minutes += d;
            if (minutes < 0) {
                minutes = 23 * 60 + 30;
            }
            if (minutes > 23 * 60 + 30) {
                minutes = 0;
            }
            let hours = Math.floor(minutes / 60);
            let remainingMinutes = minutes % 60;
            this.startTime = this.pad(hours, 2, "0") + ":" + this.pad(remainingMinutes, 2, "0");
        },
        pad(str, l, c) {
            str = str.toString();
            while (str.length < l) {
                str = c + str;
            }
            return str;
        },
        proceedAcceptChallenge() {
            this.$router.push("/fitnessplan/challenge/showSummary?startDate=" + this.startDate +
                "&startTime=" + this.startTime +
                "&id=" + this.$route.query.id);
        },
    }
}
</script>

<style lang="scss" scoped>

    /* TODO: REMOVE CODE IF SCSS FROM PLAYLISTCREATENAME EFFECTS GLOBALLY */
    .playlistCreate {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        h1 {
            margin-top: 31px;
            margin-bottom: 7px;
        }

        p {
            margin-bottom: 25px;
        }

        .list__item__label {
            font-size: 17px;
        }

        .button {
            margin-top: auto;
        }
    }

    .stepper {
        padding: 8px 16px;
        background: $colorWhite;
        border-radius: 4px;
        box-shadow: 0 8px 16px 0 rgba($colorBlack, .08), 0 0 0 1px rgba($colorBlack, .08);
        margin-top: 23px;
    }

    .stepper__value {
        font-size: 20px !important;
    }
</style>